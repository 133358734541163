import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Box from '@material-ui/core/Box';

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  return videoTrack ? (
    process.env.REACT_APP_USE_CENTERING_FIXES ? (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <VideoTrack track={videoTrack} isLocal />
      </Box>
    ) : (
      <VideoTrack track={videoTrack} isLocal />
    )
  ) : null;
}
