import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useLocalAudioToggle from '../useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../useLocalVideoToggle/useLocalVideoToggle';
import useRoomState from '../useRoomState/useRoomState';
import { Participant } from 'twilio-video';

/**
 * Timing and react hooks are complicated.  A parent render or dependent variable could cause the timer
 * to reset incorrectly in a naive implemention.
 * This implementation works by carefully filtering data down to the a single criteria to prevent incorrect resets.
 * It should be able to survive parent component rendering, and restart the timer appropriately
 * with join/drop/rejoin events from other participants or from self.
 */
export default function useInactivityTimer() {
  const timeoutInMillis = Number(process.env.REACT_APP_INACTIVITY_TIMER_IN_MIN) * 60 * 1000;
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const [participantCount, setParticipantCount] = useState(0);
  const [timerEnabled, setTimerEnabled] = useState(false);
  const [inactive, setInactive] = useState(false);

  function clearInactive() {
    setInactive(false);
  }

  /*
    This effect maintains participant count.
    Since the user could join a room with participants, the initial count comes from the room object itself.
    During the session, the room object will receive events and update the count.
    Unfortunately we were not able to reuse the useParticipants hook because it assumes the room is always
    currently available and errors out trying to produce a list of participants.
     */
  useEffect(() => {
    if (roomState !== 'connected') {
      return;
    }
    if (roomState === 'connected') {
      const size = Array.from(room.participants.values()).length;
      setParticipantCount(size);
    }

    const connectParticipant = (part: Participant) => {
      setParticipantCount(current => current + 1);
    };
    const disconnectParticipant = (part: Participant) => {
      setParticipantCount(current => current - 1);
    };

    room.on('participantConnected', connectParticipant);
    room.on('participantDisconnected', disconnectParticipant);
    return () => {
      room.off('participantConnected', connectParticipant);
      room.off('participantDisconnected', disconnectParticipant);
    };
  }, [room, roomState]);

  /*
    This effect collapses the triggers into one conditional reduce dependency arrays further down.
     */
  useEffect(() => {
    setTimerEnabled(
      roomState === 'connected' && participantCount === 0 && Boolean(process.env.REACT_APP_USE_INACTIVITY_TIMER)
    );
  }, [roomState, participantCount]);

  /*
    Effect to trigger the flag
    There are other components online which attempt to abstract the timer to another use* function,
    but all of them seem to have disclaimers about how unreliable the timing is.
     */
  useEffect(() => {
    if (!timerEnabled) {
      return;
    }
    const timer = setTimeout(() => {
      setInactive(true);
    }, timeoutInMillis);
    return () => {
      clearTimeout(timer);
    };
  }, [timerEnabled, timeoutInMillis]);

  /*
    Finally, effect to set inactive.  This may be execute due to the user enabling/disabling audio and video tracks,
    but it should only clean up the tracks and disconnect from the room when the inactive flag is triggered.
    We defer the various track variables to their own effect in order to not inadvertantly restart the timer.
     */
  useEffect(() => {
    if (!inactive) {
      return;
    }

    try {
      if (room.state === 'connected') {
        room.disconnect();
      }

      if (isAudioEnabled) {
        toggleAudioEnabled();
      }

      if (isVideoEnabled) {
        toggleVideoEnabled();
      }
    } catch (err) {
      console.log('error executing the inactivity timer: ', err);
    }
  }, [inactive, room, isAudioEnabled, toggleAudioEnabled, isVideoEnabled, toggleVideoEnabled]);

  return { participantCount, roomState, timerEnabled, inactive, clearInactive };
}
