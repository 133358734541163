export default function logMessage(message = '', code?: number) {
  console.log('Logging message with: ' + code + ':' + message);

  const csrfParameterMeta: any = document.querySelector('meta[name="_csrf_parameter"]');
  const csrfTokenMeta: any = document.querySelector('meta[name="_csrf"]');

  const csrfParameter = csrfParameterMeta != null ? csrfParameterMeta.content : 'undefined';
  const csrfToken = csrfTokenMeta != null ? csrfTokenMeta.content : 'undefined';

  if (csrfParameter === 'undefined' || csrfToken === 'undefined') {
    console.log('Could not get csrf parameter or token');
    return;
  }

  const w: any = window;
  const roomSid: String = w.roomSid || 'roomSid_not_found';

  const params = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      roomSid: roomSid,
      message: message,
      code: code,
    }),
  };

  const url = 'log.json?' + csrfParameter + '=' + csrfToken;

  fetch(url, params)
    .then(response => {
      if (!response.ok) {
        console.error('Failed to log, received status ' + response.status + ' for ' + params);
      }
    })
    .catch(error => {
      console.error('Unknown error while trying to log ' + error + ' for ' + params);
    });
}
